
import { OrderingSubjectView } from "@/models/entities/ordering-subject-view.interface";
import { CategoryPurpose } from "@/models/entities/category-purpose.interface";
import { GenerateXml } from "@/models/entities/generate-xml.interface";
import { orderingSubjectService } from "@/services/api/ordering-subject.service";
import { downloadXmlService } from "@/services/api/generate-xml.service";
import { categoryPurposeService } from "@/services/api/category-purpose.service";
import { excelDataManagerService } from "@/services/api/excel-data-manager.service";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import SearchBarModule from "@/store/modules/searchBar.module";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
  Beneficiary,
  BeneficiaryXMLRequest,
} from "@/models/utils/beneficiary.interface";
import {
  CellType,
  DataTable,
  Header,
  SnackbarModule,
  TableAction,
  ZFileInput,
} from "@zelando/vuelando";
import { required, generalFiscalCode, maxLenght } from "@/helpers/rules";
import PageTitleModule from "@/store/modules/pageTitle.module";
import { BreadCrumb } from "@/models/utils/breadcrumb.interface";
import { MenuItems } from "@/models/utils/menu-items.interface";
import { authenticate } from "@/services/api/authenticate.service";
import { TransfersUsed } from "@/models/entities/transfers-used.interface";
import { overviewService } from "@/services/api/overview.service";
import TextHighlight from "vue-text-highlight";
import IBANTTT from "iban";
import {
  VMenuDivider,
  VMenuItem,
} from "@zelando/vuelando/dist/types/models/utils/v-menu-item.interface";
import { userService } from "@/services/api/user.service";

const authModule = getModule(AuthModule, store);

const pageTitleModule = getModule(PageTitleModule, store);

const overlayModule = getModule(OverlayModule, store);

const searchBarModule = getModule(SearchBarModule, store);

const snackbarModule = getModule(SnackbarModule, store);

Vue.component("text-highlight", TextHighlight);

const DownloadComplete = () => import("./Components/DownloadComplete.vue");

@Component({
  components: {
    "download-complete": DownloadComplete,
  },
})
export default class Home extends Vue {
  drawerResize(): void {
    this.mini = this.mini && !this.$vuetify.breakpoint.mdAndUp;
  }

  path = require("@/assets/CBILogoRed.png");

  private requiredRule = required;

  private drawer = true;
  private mini = true;
  private isFormValid = false;

  private categoryPurpose: CategoryPurpose[] = [];
  private categoryPurposeDescr = 23;

  private dateRequest = new Date().toFormat("yyyy-MM-dd");
  private dateRequestMin = new Date().toFormat("yyyy-MM-dd");

  private showAddOrderingSubject = false;
  private orderingSubject: OrderingSubjectView[] = [];
  private orderingSubjectID = 0;

  private file: File = null;
  private fileNextStep: File[] = null;

  private invalidRows: Beneficiary[] = [];
  private generateXml: GenerateXml = null;
  private dialog = false;
  private downloadComplete = false;

  private disableModifyRow = false;

  private totalBeneficiaries = "--";

  private loading = false;

  private showAlertWrongImport = false;

  // private value = 0;

  private beneficiaries: Beneficiary[] = [];
  private tableIsVisible = false;

  private uploadValue = true;

  private pxDistance = "250px";

  private inEditing: Beneficiary = null;

  private foreignIban = false;
  private toggle = false;

  private descrDialogError: string[] = [];

  private deleteDialog = false;

  private removeForeignIban = false;

  private selectedWrong = true;
  private selectedDuplicates = true;

  private ineditingDialog = false;

  private loadPage = false;

  private consumptionLocked = false;
  private countBeneficiaries = 0;
  private money = {
    decimal: ",",
    thousands: ".",
    precision: 2,
  };

  private transfersUsed: TransfersUsed = {
    beneficiariesCount: 0,
    monthlyBeneficiaries: 0,
  };

  private removeChanges: Beneficiary = {
    excelRowIndex: 0,
    subjectName: "",
    fiscalCode: "",
    iban: "",
    description: "",
    amount: null,
    locality: "",
    province: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    isSelected: true,
    isValid: false,
    isRed: [],
  };

  private threedots: (VMenuItem | VMenuDivider)[] = [
    {
      label: this.$tc("home.DownloadTemplate"),
      iconName: "download",
      action: () => {
        this.downloadModel();
      },
    },
    "divider",
    {
      label: this.$tc("home.AllowForeignBeneficiaries"),
      iconName: "",
      action: () => {
        this.changedField();
      },
    },
  ];

  public breadcrumb: BreadCrumb[] = [
    {
      text: this.$tc("breadcrumb.Home"),
      disabled: true,
      href: "/home",
      current: true,
    },
  ];

  private fileExtension: string[] = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  private menuItems: MenuItems[] = [
    {
      label: this.$tc("home.AddBeneficiary"),
      iconName: "plus",
      action: () => {
        return this.uploadExcel(true);
      },
    },
    {
      label: this.$tc("home.ReplaceBeneficiary"),
      iconName: "switch",
      action: () => {
        return this.uploadExcel(false);
      },
    },
  ];

  private headersForeignIban: Header[] = [
    {
      text: "",
      value: "formValidator",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.SubjectName"),
      value: "subjectName",
      cellType: CellType.CUSTOM,
      rules: [required, maxLenght],
    },
    {
      text: this.$tc("home.FiscalCode"),
      value: "fiscalCode",
      cellType: CellType.CUSTOM,
      rules: [generalFiscalCode],
    },
    {
      text: this.$tc("home.Iban"),
      value: "iban",
      cellType: CellType.CUSTOM,
      rules: [required],
    },
    {
      text: this.$tc("home.Description"),
      value: "description",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Amount"),
      value: "amount",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Locality"),
      value: "locality",
      cellType: CellType.CUSTOM,
      rules: [required],
    },
    {
      text: this.$tc("home.Province"),
      value: "province",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Address"),
      value: "address",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.PostalCode"),
      value: "postalCode",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Country"),
      value: "country",
      cellType: CellType.CUSTOM,
      rules: [required],
    },
    {
      text: "    ",
      value: "actionBtn",
      cellType: CellType.CUSTOM,
    },
  ];

  private headers: Header[] = [];

  private headersNormal: Header[] = [
    {
      text: "",
      value: "formValidator",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.SubjectName"),
      value: "subjectName",
      cellType: CellType.CUSTOM,
      rules: [required],
    },
    {
      text: this.$tc("home.FiscalCode"),
      value: "fiscalCode",
      cellType: CellType.CUSTOM,
      rules: [generalFiscalCode],
    },
    {
      text: this.$tc("home.Iban"),
      value: "iban",
      cellType: CellType.CUSTOM,
      rules: [required],
    },
    {
      text: this.$tc("home.Description"),
      value: "description",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Amount"),
      value: "amount",
      cellType: CellType.CUSTOM,
    },
    {
      text: "",
      value: "actionBtn",
      cellType: CellType.CUSTOM,
    },
  ];

  private tableOptions: DataTable<Beneficiary> = {
    key: "excelRowIndex",
    loading: false,
    search: "",
    actions: [
      {
        name: "edit",
        icon: "trash",
        tooltip: this.$tc("home.Delete"),
        size: "extra-small",
      } as TableAction,
    ],
    headers: this.headers,
    values: this.beneficiaries,
  };

  private tempAmount: string = null;

  private get showLinkDifference(): boolean {
    return (
      !this.consumptionLocked &&
      this.transfersUsed.difference < 1 &&
      authModule.profileRoleID === 2
    );
  }

  private get breadcrumbs(): BreadCrumb[] {
    return pageTitleModule.breadcrumb;
  }

  private get username(): string {
    return authModule.userName;
  }

  private get email(): string {
    return authModule.userEmail;
  }

  private get getWrongRows(): number {
    return this.beneficiaries.filter((x) => x.isValid == false).length;
  }

  //get duplicate iban
  private findDoubledBeneficiaries(bens: Beneficiary[]): Beneficiary[] {
    const allIbans: string[] = bens.map((x) => x.iban);
    const doubledIbans: string[] = allIbans.filter((val, idx, arr) => {
      return arr.indexOf(val) !== idx;
    });
    return bens.filter((x) => {
      return doubledIbans.includes(x.iban);
    });
  }

  // check: stato inserito != stato IBAN (se compilati)
  private findCountriesAnomaliesInIban(bens: Beneficiary[]): Beneficiary[] {
    return bens.filter((x) => {
      if (x.iban?.length > 1 && x.country?.length > 1) {
        return (
          x.iban?.slice(0, 2)?.toUpperCase() !==
          x.country?.slice(0, 2)?.toUpperCase()
        );
      }
      return false;
    });
  }

  private get getDoubleNumber(): number {
    return this.findDoubledBeneficiaries(this.beneficiaries)?.length ?? 0;
  }

  private get getCountriesAnomaliesInIban(): number {
    return this.findCountriesAnomaliesInIban(this.beneficiaries)?.length ?? 0;
  }

  private get drawerType(): string {
    if (this.getWrongRows > 0 && this.tableIsVisible) {
      return "error-background mt-auto";
    }
    if (
      (this.getDoubleNumber > 0 || this.getCountriesAnomaliesInIban > 0) &&
      this.tableIsVisible
    ) {
      return "warning";
    }
    if (
      this.beneficiaries.filter((x) => x.isValid == false).length == 0 &&
      this.tableIsVisible
    ) {
      return "correct";
    }

    return "default";
  }

  private get totalAmount(): string {
    const total = this.beneficiaries.slice().reduce((aggr, el) => {
      const amount =
        el.amount != null && el.amount != ""
          ? el.amount.replaceAll(".", "").replaceAll(",", ".")
          : "0";

      const tempAmount = parseFloat(amount);
      aggr += !isNaN(tempAmount)
        ? tempAmount
        : parseFloat(this.tempAmount.replaceAll(".", "").replaceAll(",", "."));

      return aggr;
    }, 0);

    const ris = total.toLocaleString("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return ris != "0" || ris == null ? ris : "--";
  }

  async created(): Promise<void> {
    this.mini = false;
    searchBarModule.setVisibleAction(false);
    pageTitleModule.setBreadCrumb(this.breadcrumb);
    this.dateRequest = new Date().toISOString().split("T")[0];

    //hide scroll bar page
    window.addEventListener("beforeunload", this.onClose);
    document
      .getElementsByTagName("html")
      .item(0)
      .style.setProperty("overflow", "hidden");

    //get expiredPlans
    this.consumptionLocked = (await userService.GetConsumptionLocked()).data;

    //get used transfers
    overlayModule.showOverlay();
    await this.GetNumTransfersByMonth();

    //get data orderingSubject
    const orderingSubjects = await orderingSubjectService.ReadAll(
      authModule.userID
    );
    this.orderingSubject = orderingSubjects.data;
    if (this.orderingSubject.length > 0) {
      this.orderingSubjectID = this.orderingSubject[0].orderingSubjectID;
      this.orderingSubject.forEach((x) => {
        if (x.isDefault) {
          this.orderingSubjectID = x.orderingSubjectID;
        }
      });
    } else {
      this.showAddOrderingSubject = true;
    }

    //get data categoryPurposeSync
    const categoryPurpose = await categoryPurposeService.GetCategoryPurpose();
    this.categoryPurpose = categoryPurpose.data;

    //get headers
    this.headers = this.headersForeignIban; // this.headersNormal;
    this.$set(this.tableOptions, "headers", this.headers);

    if (this.$vuetify.breakpoint.lgAndUp) {
      this.mini = false;
    }
    this.loadPage = true;
  }

  private async logout(): Promise<void> {
    await authenticate.Logout();
    this.$router.push({
      name: "login",
    });
  }

  private profileClicked(): void {
    this.$router.push({
      name: "account",
    });
  }

  private goHome() {
    this.$router.push({ name: "home" });
  }

  //add field for foreign iban
  private changedField(): void {
    let exists = false;
    if (this.foreignIban) {
      this.beneficiaries.forEach((x) => {
        if (
          x.locality != "" &&
          x.province != "" &&
          x.country != "" &&
          x.locality != ""
        ) {
          exists = true;
        }
      });
      if (exists) {
        this.removeForeignIban = true;
      } else {
        this.removeForeignField();
      }
    } else {
      this.headers = this.headersForeignIban;
      this.foreignIban = true;
      this.toggle = true;
    }

    this.$set(this.tableOptions, "headers", this.headers);
  }

  private removeForeignField(): void {
    // this.headers = this.headersNormal;
    this.headers = this.headersForeignIban;
    this.foreignIban = false;
    this.toggle = false;
    this.removeForeignIban = false;
    this.$set(this.tableOptions, "headers", this.headers);
  }

  private removeForeign(): void {
    this.beneficiaries.forEach((x) => {
      x.locality = "";
      x.province = "";
      x.country = "";
      x.locality = "";
    });
    // this.headers = this.headersNormal;
    this.headers = this.headersForeignIban;
    this.foreignIban = false;
    this.toggle = false;
    this.removeForeignIban = false;
    this.$set(this.tableOptions, "headers", this.headers);
  }

  private async GetNumTransfersByMonth(): Promise<void> {
    await overviewService.GetNumTransfersByMonth().then((x) => {
      if (x.data) {
        this.transfersUsed.beneficiariesCount = x.data.beneficiariesCount;
        this.transfersUsed.monthlyBeneficiaries = x.data.monthlyBeneficiaries;
        const difference =
          x.data.monthlyBeneficiaries - x.data.beneficiariesCount;
        Vue.set(this.transfersUsed, "difference", difference);
      }
    });
  }

  private async fileExecute(): Promise<void> {
    var error = false;
    if (this.file != null) {
      try {
        overlayModule.showOverlay();
        //get elaboreate data from excel
        this.countBeneficiaries = 0;
        const x = await excelDataManagerService.GetDataByExcel(this.file);
        let newBene = this.validateRow(x.data);
        this.addBeneficiaryToArray(newBene);
        this.$set(this.tableOptions, "values", this.beneficiaries);
      } catch (e) {
        if (e.response.status == 409) error = true;
        snackbarModule.showSnackbar({
          message: this.$tc("home.BeneficiaryOutOfBound"),
          type: "error",
          timer: 5000,
        });
      }
    }
    this.parseAmount(this.beneficiaries);
    if (this.beneficiaries.length > 0) {
      this.tableIsVisible = true;
      for (const beneficary of this.beneficiaries) {
        if (beneficary.subjectName.length > 70) {
          snackbarModule.showSnackbar({
            message: this.$tc("home.OverflowText"),
            type: "error",
            dismissable: true,
          });
          break;
        }
      }
      searchBarModule.setVisibleAction(true);
    } else {
      if (error == false) {
        this.showAlertWrongImport = true;
        searchBarModule.setVisibleAction(false);
      }
    }
  }

  private async fileExecuteNextStep(): Promise<void> {
    if (this.fileNextStep[0] != null) {
      overlayModule.showOverlay();
      try {
        const x = await excelDataManagerService.GetDataByExcel(
          this.fileNextStep[0]
        );

        if (this.uploadValue) {
          let newBene = this.validateRow(x.data);

          this.parseAmount(newBene);

          this.addBeneficiaryToArray(newBene);
        } else {
          this.countBeneficiaries = 0;
          let newBene = this.validateRow(x.data);
          this.beneficiaries = [];

          this.addBeneficiaryToArray(newBene);

          this.parseAmount(this.beneficiaries);
        }
        this.$set(this.tableOptions, "values", this.beneficiaries);
        await this.$nextTick();
      } catch (e) {
        if (e.response.status == 409)
          snackbarModule.showSnackbar({
            message: this.$tc("home.BeneficiaryOutOfBound"),
            type: "error",
            timer: 5000,
          });
      }
    }
    if (this.beneficiaries.length > 0) {
      this.tableIsVisible = true;
      for (const beneficary of this.beneficiaries) {
        if (beneficary.subjectName.length > 70) {
          snackbarModule.showSnackbar({
            message: this.$tc("home.OverflowText"),
            type: "error",
            dismissable: true,
          });
          break;
        }
      }
      searchBarModule.setVisibleAction(true);
    } else {
      this.showAlertWrongImport = true;
      this.tableIsVisible = false;
      searchBarModule.setVisibleAction(false);
    }
  }

  private uploadExcel(value: boolean): void {
    (this.$refs.file as ZFileInput).triggerClick();
    this.uploadValue = value;
  }

  private async addBeneficiary(): Promise<void> {
    if (!this.beneficiaries.includes(this.inEditing)) {
      const beneficiary = {
        excelRowIndex: this.beneficiaries.length,
        subjectName: "",
        fiscalCode: "",
        iban: "",
        description: "",
        amount: null,
        locality: "",
        province: "",
        address: "",
        postalCode: "",
        city: "",
        country: "",
        duplicate: false,
        isSelected: true,
        isValid: false,
        isRed: [],
      };
      // const index = this.beneficiaries.length;
      this.beneficiaries.push(beneficiary);
      this.inEditing = beneficiary;
      this.tableIsVisible = true;
      this.$set(this.tableOptions, "values", this.beneficiaries);
      this.drawer = true;
    }
  }

  hasNewXMLFormat = true;
  private async downloadXml(): Promise<void> {
    this.loading = true;
    overlayModule.showOverlay();

    const xmlBen: BeneficiaryXMLRequest[] = this.beneficiaries.map((x) => {
      return {
        subjectName: x.subjectName,
        fiscalCode: x.fiscalCode,
        locality: x.locality,
        province: x.province,
        address: x.address,
        postalCode: x.postalCode,
        iban: x.iban,
        description: x.description,
        amount: x.amount,
        city: x.city,
        country: x.country,
      };
    });
    this.generateXml = {
      beneficiaries: xmlBen,
      orderingSubjectID: this.orderingSubjectID,
      requestDate: this.dateRequest,
      categoryPurpose: this.categoryPurpose[this.categoryPurposeDescr - 1].code,
      userId: authModule.userID,
      firstTime: true,
      hasNewXMLFormat: this.hasNewXMLFormat,
    };
    try {
      const x = await downloadXmlService.GetDownloadXml(this.generateXml, true);
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/xml;charset=utf-8," + encodeURIComponent(x.data)
      );
      const fileName =
        (!this.hasNewXMLFormat
          ? this.$tc("downloadNewXMLFormat.FileNameOld")
          : this.$tc("downloadNewXMLFormat.FileName")) +
        new Date().toFormat("yyyy_MM_dd_HH_mm");
      element.setAttribute("download", fileName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.appendChild(element);
      this.downloadComplete = true;
      searchBarModule.setVisibleAction(false);
      await this.GetNumTransfersByMonth();
    } catch (e) {
      if (e.response.status === 400 || e.response.status === 500) {
        snackbarModule.hideSnackbar();
        snackbarModule.showSnackbar({
          message: e.response.data
            ? this.$tc(e.response?.data.toString())
            : this.$tc("home.LicenceExpired"),
          type: "error",
          timer: 5000,
        });
      } else if (e.response.status == 409) {
        snackbarModule.showSnackbar({
          message: this.$tc("home.BeneficiaryOutOfBound"),
          type: "error",
          timer: 5000,
        });
      }
    }
  }

  private newOrderingSubject(): void {
    this.$router.push({
      name: "account",
      params: {
        propOrderingSubject: "true",
        context: "orderingSubjectList",
      },
    });
  }

  private downloadModel(): void {
    window.open("https://cbifast.com/public/modello_cbi.xlsx");
  }

  //open for downloadXml
  private openDialog() {
    this.dialog = true;
  }

  private confirmDialog() {
    this.downloadXml();
    this.dialog = false;
  }

  private undoDialog() {
    this.dialog = false;
  }

  private closeDownloadComplete(): void {
    this.downloadComplete = false;
    this.tableIsVisible = false;
    this.loading = false;
    this.beneficiaries = [];
    this.$set(this.tableOptions, "values", this.beneficiaries);
  }

  //select frong row
  private selectWrong(): void {
    if (this.selectedWrong) {
      this.selectedWrong = false;
      this.invalidRows = this.beneficiaries.filter((x) => x.isValid == false);
    } else {
      this.selectedWrong = true;
      this.invalidRows = [];
    }
  }

  //select double row
  private getDouble(): void {
    if (this.selectedDuplicates) {
      this.invalidRows = this.findDoubledBeneficiaries(this.beneficiaries); //  doubled;
      this.selectedDuplicates = false;
    } else {
      this.selectedDuplicates = true;
      this.invalidRows = [];
    }
  }

  private getCountriesAnomaliesIban(): void {
    if (this.selectedDuplicates) {
      this.invalidRows = this.findCountriesAnomaliesInIban(this.beneficiaries); //  doubled;
      this.selectedDuplicates = false;
    } else {
      this.selectedDuplicates = true;
      this.invalidRows = [];
    }
  }

  private modifyRow(item: Beneficiary) {
    this.removeChanges = { ...item };
    this.disableModifyRow = true;
    this.tempAmount = item.amount;
    this.inEditing = item;
    setTimeout(() => {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    }, 0);
  }

  private removeChangesRow(item: Beneficiary) {
    let index = 0;
    for (let i = 0; i < this.beneficiaries.length; i++) {
      if (this.beneficiaries[i].excelRowIndex == item.excelRowIndex) {
        index = i;
        break;
      }
    }
    this.inEditing = null;
    this.beneficiaries[index].iban = this.removeChanges.iban;
    this.beneficiaries[index].postalCode = this.removeChanges.postalCode;
    this.beneficiaries[index].city = this.removeChanges.city;
    this.beneficiaries[index].country = this.removeChanges.country;
    this.beneficiaries[index].subjectName = this.removeChanges.subjectName;
    this.beneficiaries[index].fiscalCode = this.removeChanges.fiscalCode;
    this.beneficiaries[index].description = this.removeChanges.description;
    this.beneficiaries[index].amount = this.removeChanges.amount;
    this.beneficiaries[index].locality = this.removeChanges.locality;
    this.beneficiaries[index].province = this.removeChanges.province;
    this.beneficiaries[index].address = this.removeChanges.address;
    this.beneficiaries[index].address = this.removeChanges.address;
    this.beneficiaries[index].isValid = this.removeChanges.isValid;
    this.beneficiaries[index].isRed = this.removeChanges.isRed;

    this.$set(this.tableOptions, "values", this.beneficiaries);
    this.disableModifyRow = false;
  }

  private checkIban(iban: string): boolean[] {
    var IBAN = IBANTTT;
    var valid: boolean[] = [];
    valid.push(IBAN.isValid(iban));
    return valid;
  }

  private saveChangesRow(arrayIndex: number) {
    let errorString = this.$tc("home.RowErrorSnackbar");
    const validForm = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    let index = 0;
    for (let i = 0; i < this.beneficiaries.length; i++) {
      if (this.beneficiaries[i].excelRowIndex == arrayIndex) {
        index = i;
        break;
      }
    }
    var IBAN = IBANTTT;
    this.beneficiaries[index].isValid = validForm;

    if (!IBAN.isValid(this.beneficiaries[index].iban)) {
      this.beneficiaries[index].isValid = false;
    }

    this.beneficiaries[index].amount = this.beneficiaries[index].amount.replace(
      "€",
      ""
    );
    if (
      this.beneficiaries[index].amount == "0" ||
      this.beneficiaries[index].amount == "0,00"
    ) {
      this.beneficiaries[index].isRed.push("amount");
      this.beneficiaries[index].isValid = false;
    }
    let textOverflow = false;
    this.parseAmountSingle(this.beneficiaries[index]);
    Object.keys(this.beneficiaries[index]).forEach((y) => {
      let errorStringLength = this.isMaxLengthBroken(
        this.beneficiaries[index],
        y
      );
      if (
        (required(this.beneficiaries[index][y]) !== true &&
          y != "locality" &&
          y != "province" &&
          y != "address" &&
          y != "postalCode" &&
          y != "city" &&
          y != "country" &&
          y != "errorRef" &&
          y != "excelRowIndex" &&
          y != "isValid" &&
          y != "description" &&
          y != "fiscalCode") ||
        (y === "fiscalCode" &&
          this.beneficiaries[index][y] != null &&
          generalFiscalCode(this.beneficiaries[index][y]) !== true &&
          ![11, 16].includes(this.beneficiaries[index][y].length)) ||
        // this.beneficiaries[index][y].length != 11) ||
        errorStringLength != ""
      ) {
        this.beneficiaries[index].isValid = false;
        if (errorStringLength != "") {
          errorString = errorStringLength;
          textOverflow = true;
        }
      }
    });
    if (textOverflow) {
      snackbarModule.showSnackbar({
        message: this.$tc("home.OverflowText"),
        type: "error",
        dismissable: true,
      });
    }
    if (this.beneficiaries[index].isValid == true) {
      if (this.checkForeignIban(this.beneficiaries[index])) {
        this.disableModifyRow = false;
        this.inEditing = null;
        this.beneficiaries[index].isRed = [];
        if (!this.beneficiaries[index].iban.startsWith("IT")) {
          this.headers = this.headersForeignIban;
          this.foreignIban = true;
          this.toggle = true;
          this.$set(this.tableOptions, "headers", this.headers);
        }
      } else {
        this.beneficiaries[index].isValid = false;
        this.beneficiaries[index].isRed.push("locality");
        snackbarModule.showSnackbar({
          message: this.$tc("home.RowForeignCheckSnackbar"),
          type: "error",
          timer: 7500,
        });
      }
    } else {
      snackbarModule.showSnackbar({
        message: errorString,
        type: "error",
        timer: 5000,
      });
    }
    // Also check for required fields
  }

  // ___ check, if foreign IBAN, for proper filling.
  checkForeignIban(ben: Beneficiary): boolean {
    if (ben?.iban?.length > 2) {
      const countryCode: string = ben?.iban?.slice(0, 2);
      if (countryCode?.toLowerCase() !== "it") {
        return ben.locality?.length > 0;
      }
    }
    return true;
  }

  //prevent from close tab
  onClose(event: BeforeUnloadEvent): void {
    if (
      this.beneficiaries != null &&
      this.beneficiaries.length != 0 &&
      this.$router.currentRoute.path == "/home"
    ) {
      event.preventDefault();
      event.returnValue = "Are you sure you want to exit?";
    }
  }

  private parseAmount(beneficiaries: Beneficiary[]) {
    let ris = "";
    beneficiaries.forEach((x) => {
      if (x.iban != null) {
        x.iban = x.iban.trim().replaceAll(/\s/g, "");
      }
      if (x.amount != null && x.amount != "") {
        let obj = x.amount.replaceAll(",", ".");
        ris = parseFloat(obj).toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        ris = "0";
      }
      x.amount = ris;
    });
  }

  private parseAmountSingle(beneficiaries: Beneficiary) {
    let ris = "";
    if (beneficiaries.amount != null && beneficiaries.amount != "") {
      if (beneficiaries.iban != null) {
        beneficiaries.iban = beneficiaries.iban.trim().replaceAll(/\s/g, "");
      }
      ris = parseFloat(
        beneficiaries.amount.replaceAll(".", "").replaceAll(",", ".")
      ).toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      ris = "0";
    }
    beneficiaries.amount = ris;
  }

  //dynamic validation of row
  private validateRow(beneficiaries: Beneficiary[]): Beneficiary[] {
    this.descrDialogError = [];
    var IBAN = IBANTTT;
    let newBene = beneficiaries.map((x) => {
      x.isValid = true;
      x.isRed = [];
      if (x.amount === "0") {
        // Set as "0" from backend if not valid!
        x.isRed.push("amount");
        x.isValid = false;
      }
      if (!IBAN.isValid(x.iban)) {
        x.isRed.push("iban");
        x.isValid = false;
        if (!this.descrDialogError.includes(this.$tc("home.Iban"))) {
          this.descrDialogError.push(this.$tc("home.Iban"));
        }
      }
      if (!this.checkForeignIban(x)) {
        x.isRed.push("locality");
        x.isValid = false;
        if (
          !this.descrDialogError.includes(
            this.$tc("home.RowForeignCheckSnackbar")
          )
        ) {
          this.descrDialogError.push(this.$tc("home.RowForeignCheckSnackbar"));
        }
      }
      if (!x.locality || !x.country) {
        x.isRed.push("locality");
        x.isValid = false;
        if (
          !this.descrDialogError.includes(this.$tc("home.RowCbiRequiredFields"))
        ) {
          this.descrDialogError.push(this.$tc("home.RowCbiRequiredFields"));
        }
      }
      for (var y of Object.keys(x)) {
        if (
          (required(x[y]) !== true &&
            y !== "locality" &&
            y !== "province" &&
            y !== "address" &&
            y !== "postalCode" &&
            y !== "city" &&
            y !== "country" &&
            y !== "errorRef" &&
            y !== "excelRowIndex" &&
            y !== "isValid" &&
            y !== "description" &&
            y !== "fiscalCode") ||
          (y === "fiscalCode" &&
            x[y] != null &&
            generalFiscalCode(x[y]) !== true &&
            ![11, 16].includes(x[y].length)) ||
          this.isMaxLengthBroken(x[y], y) != ""
        ) {
          x.isRed.push(y.toString());
          x.isValid = false;
        }
      }

      x.excelRowIndex = this.countBeneficiaries;
      this.countBeneficiaries++;
      return x;
    });
    return newBene;
  }

  isMaxLengthBroken(b: Beneficiary, i: string): string {
    if (b && b[i]) {
      switch (i) {
        case "subjectName":
          return b[i].length > 70
            ? this.$tc("api.xmlSubjectNameLengthError")
            : "";
        case "fiscalCode":
          return b[i].length > 35
            ? this.$tc("api.xmlFiscalCodeLengthError")
            : "";
        case "iban":
          return b[i].length > 34 ? this.$tc("api.xmlIbanLengthError") : "";
        case "description":
          return b[i].length > 140
            ? this.$tc("api.xmlDescriptionLengthError")
            : "";
        case "amount":
          return b[i].length > 24 ? this.$tc("api.xmlAmountLengthError") : "";
        case "locality":
          return b[i].length > 35 ? this.$tc("api.xmlLocalityLengthError") : "";
        case "province":
          return b[i].length > 35 ? this.$tc("api.xmlProvinceLengthError") : "";
        case "address":
          return b[i].length > 70 ? this.$tc("api.xmlAddressLengthError") : "";
        case "postalCode":
          return b[i].length > 16
            ? this.$tc("api.xmlPostalCodeLengthError")
            : "";
        case "city":
          return b[i].length > 35 ? this.$tc("api.xmlCityLengthError") : "";
        case "country":
          return b[i].length > 2 ? this.$tc("api.xmlCountryLengthError") : "";
        default:
          return "";
      }
    }
    return "";
  }

  //add beneficary to array
  private addBeneficiaryToArray(beneficiaries: Beneficiary[]) {
    beneficiaries.forEach((beneficiary: Beneficiary) => {
      if (!beneficiary.iban.startsWith("IT")) {
        this.headers = this.headersForeignIban;
        this.foreignIban = true;
        this.toggle = true;
        this.$set(this.tableOptions, "headers", this.headers);
      }
      this.beneficiaries.push(beneficiary);
    });
  }

  private deleteRow() {
    this.invalidRows.forEach((beneficiary: Beneficiary) => {
      for (let i = 0; i < this.beneficiaries.length; i++) {
        if (this.beneficiaries[i].excelRowIndex == beneficiary.excelRowIndex) {
          this.beneficiaries.splice(i, 1);
        }
      }
    });
    this.invalidRows = [];
    this.deleteDialog = false;
    if (this.beneficiaries.length == 0) {
      this.tableIsVisible = false;
      searchBarModule.setVisibleAction(false);
    }
  }

  get globalSearch(): string {
    return searchBarModule.search;
  }

  @Watch("globalSearch")
  globalSearchChanged(): void {
    this.tableOptions.search = this.globalSearch;
  }

  private customSearch(
    value: string | number,
    globalSearch: string | null,
    beneficiaries: Beneficiary
  ): boolean {
    const src = globalSearch.toLowerCase().trim();
    if (
      (beneficiaries.subjectName != null &&
        beneficiaries.subjectName.toLowerCase().includes(src)) ||
      (beneficiaries.fiscalCode != null &&
        beneficiaries.fiscalCode.toString().toLowerCase().includes(src)) ||
      (beneficiaries.iban != null &&
        beneficiaries.iban.toString().toLowerCase().includes(src)) ||
      (beneficiaries.description != null &&
        beneficiaries.description.toString().toLowerCase().includes(src)) ||
      (beneficiaries.amount != null &&
        beneficiaries.amount.toString().toLowerCase().includes(src)) ||
      (beneficiaries.locality != null &&
        beneficiaries.locality.toLowerCase().includes(src)) ||
      (beneficiaries.province != null &&
        beneficiaries.province.toLowerCase().includes(src)) ||
      (beneficiaries.address != null &&
        beneficiaries.address.toLowerCase().includes(src)) ||
      (beneficiaries.postalCode != null &&
        beneficiaries.postalCode.toLowerCase().includes(src)) ||
      (beneficiaries.city != null &&
        beneficiaries.city.toLowerCase().includes(src)) ||
      (beneficiaries.country != null &&
        beneficiaries.country.toLowerCase().includes(src))
    ) {
      return true;
    }
    return false;
  }

  private goToPlans() {
    this.$router.push({
      name: "account",
      params: {
        context: "payments",
        payments: "overview",
      },
    });
  }
}
