import { CategoryPurpose } from "@/models/entities/category-purpose.interface";
import { ApiService } from "@zelando/vuelando";

const controller = "categorypurpose";

class CategoryPurposeService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetCategoryPurpose() {
    return this.getAll<CategoryPurpose[]>(`${controller}/`);
  }
}

export const categoryPurposeService = new CategoryPurposeService();
